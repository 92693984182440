import React from "react";

import MainTitle from "components/MainTitle";
import ListModels from "./components/ListModels";
import { Section, Container } from "components/Layout/styles";

function SectionModels() {
  return (
    <Section color="#F4F8FF">
      <Container>
        <MainTitle
          titleTag="h1"
          textTag="h2"
          title={
            <>
              Tenha um site para imobiliária <br /> moderno, fácil de usar e
              personalizável
            </>
          }
          text={
            <>
              Sites imobiliários com foco na usabilidade e design. Além de
              transmitir mais profissionalismo,
              <br /> seus clientes navegarão com muita facilidade no melhor site
              de imóveis.
            </>
          }
        />
        <ListModels
          data={[
            {
              id: "sibele-imoveis",
              name: "Sibele Imóveis",
              site_url: "sibeleimoveis.com.br",
              imageUrl: "/modelos-pequenos/site-imobiliaria-sibele.png",
            },
            {
              id: "tinga-imoveis",
              name: "Tinguá Imóveis",
              site_url: "tinguaimoveis.com.br",
              imageUrl: "/modelos-pequenos/site-imobiliaria-tingua-imoveis.png",
            },
            {
              id: "bit-imoveis",
              name: "Bit Imóveis",
              site_url: "bitimoveis.com",
              imageUrl: "/modelos-pequenos/site-bit-imoveis.png",
            },
            {
              id: "imoveisfeitosa.com",
              name: "Feitosa",
              site_url: "imoveisfeitosa.com",
              imageUrl: "/modelos-pequenos/imoveisfeitosa.com.png",
            },
            {
              id: "rafaelamonuticorretora.com.br",
              name: "Rafaela Monuti",
              site_url: "rafaelamonuticorretora.com.br",
              imageUrl: "/modelos-pequenos/rafaelamonuticorretora.com.br.png",
            },
            {
              id: "rmfazendas.com.br",
              name: "RM Fazendas",
              site_url: "rmfazendas.com.br",
              imageUrl: "/modelos-pequenos/rmfazendas.com.br.png",
            },
            {
              id: "hellofloripa.com",
              name: "Hello Floripa",
              site_url: "hellofloripa.com",
              imageUrl: "/modelos-pequenos/hellofloripa.com.png",
            },
            {
              id: "casaldecorretor.com.br",
              name: "Casal corretor",
              site_url: "casaldecorretor.com.br",
              imageUrl: "/modelos-pequenos/casaldecorretor.com.br.png",
            },
            {
              id: "facilitaimoveisabc.com.br",
              name: "Facilita Imóveis",
              site_url: "facilitaimoveisabc.com.br",
              imageUrl: "/modelos-pequenos/facilitaimoveisabc.com.br.png",
            },
            {
              id: "orsibrokers.com.br",
              name: "Orsi Brokers",
              site_url: "orsibrokers.com.br",
              imageUrl: "/modelos-pequenos/orsibrokers.com.br.png",
            },
            {
              id: "site-corretoracarolferraz.png",
              name: "Ferraz Negócios",
              site_url: "corretoracarolferraz.com.br",
              imageUrl: "/modelos-pequenos/corretoracarolferraz.com.br.png",
            },
            {
              id: "vitoriasantosnegocios.com.br",
              name: "Vitória Santos",
              site_url: "vitoriasantosnegocios.com.br",
              imageUrl: "/modelos-pequenos/vitoriasantosnegocios.com.br.png",
            },
          ]}
        />
      </Container>
    </Section>
  );
}

SectionModels.propTypes = {};
SectionModels.defaultProps = {};

export default SectionModels;
